<template>
  <div class="pg">
    <Top :topIndex="2"  @changeL="changeL"></Top>
    <div style="min-height:10vh">
         <img :src="cn_enobj.site.index.home_logo"  class="zhanweiImg" alt="">
    </div>
    
    <!-- 被投案例、被投企业 -->
    <div class="investmentCase-item3" v-show="cn_enobj.site.investmentCase.investment.is_show">
        <div class="labelsTop">
            <div style="display: flex;"   class="reveal-r" >
                <div style="text-align: center;">
                    <div class="labels">{{cn_enobj.site.investmentCase.investment.title}}</div>
                    <div v-show="locale == 'zh'" class="labels-en">{{cn_enobj.site.investmentCase.investment.subtitle}}</div>
                </div>
            </div>
        </div>
        <div class="investmentCase-item3-box ">
            <div v-for="(i,index) in item3Arr" :key="index" 
             class="investmentCase-item3-box-div  reveal-bottom" 
             @mousemove="showgbbeitou=true">
                <img :src="i.pic_big"/>
                <div class="investmentCase-item3-box-card"
                 :style="{'visibility':showgbbeitou ?'':'hidden'}" >
                    <img class="investmentCase-item3-box-bglogo" :src="i.pic_big"  />
                    <img src="@/assets/img/gbbeitou.png" class="gbbeitou" @click="gbbeitouEV()" alt="">
                    <div class="investmentCase-item3-box-bg">
                        <div class="investmentCase-item3-xian"></div>
                        <div class="investmentCase-item3-box-transform">
                            <div class="investmentCase-item3-biaoti" v-if="locale == 'zh'?i.title:i.en_title" style="">{{locale == 'zh'?i.title:i.en_title}}</div>
                            <div class="investmentCase-item3-wenben" v-html="locale == 'zh'?i.introduce:i.en_introduce"></div>
                            <a class="investmentCase-item3-wanzhi" :href="i.uri"   target="_blank" >{{i.uri }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="loadMore ">
            <div class="loadMore-btn" v-if="item3Arr.length>12" @click="loadMoreEV()">{{cn_enobj.site.components.bottom.more_bottom}}</div>
        </div>
    </div>
    <!-- 投资资讯 -->
    <div class="investmentCase-item2" v-show="cn_enobj.site.investmentCase.portfolio.is_show">
        <div class="labelsTop">
            <div style="display: flex;"   class="reveal-r" >
                <div style="text-align: center;">
                    <div class="labels">{{cn_enobj.site.investmentCase.portfolio.title}}</div>
                    <div v-show="locale == 'zh'" class="labels-en">{{cn_enobj.site.investmentCase.portfolio.subtitle}}</div>
                </div>
            </div>
        </div>
        <div class="investmentCase-item2-box reveal-bottom">
            <img :src="information.pic_big" class="show1 investmentCase-item2img" alt="">
            <div class="investmentCase-item2-box-text" style="margin-right:-7.1vw ;">
                <div class="investmentCase-item2-box-text-wenben">
                    <div class="wenantitle">{{locale === 'zh'?information.title:information.en_title}}</div>
                    <div class="wenanbody">{{locale === 'zh'?information.introduce:information.en_introduce}}</div>
                </div>
                <div class="imgbtn" @click="goDetaile(information)">VIEW MORE</div>
            </div>
            <img :src="information.pic_big" class="show2 investmentCase-item2img" alt="">
            
        </div>
    </div>
    <Bottom></Bottom>
    <ReturnTop></ReturnTop>
  </div>
</template>

<script>
import Top from '@/components/top.vue'
import Bottom from '@/components/bottom.vue'
import ReturnTop from '@/components/returnTop.vue'
import scrollReveal from 'scrollreveal'
export default {  
    components:{Top,Bottom,ReturnTop},
    data () {
        return {
            scrollReveal: scrollReveal(),
            item3Arr:[],
            locale:'',
            page:1,
            information:{},
            isDisable:false, // 没有数据时禁止请求
            cn_enobj:{}, 
            showgbbeitou:false
        }
    },
   
     //项目中执行，自定义类名。
    mounted() {
        this.scrollReveal.reveal('.reveal-bottom', {origin: 'bottom',...this.global});
        this.scrollReveal.reveal('.reveal-r', {...this.global,distance: '0px', scale: 0.9});
        
    },
    created(){
        this.getBusinessData()  // 被投企业
        this.getInformationData() // 投资资讯
        this.changeL(this.$i18n.locale)
    },
    methods: {
        changeL(str){
            this.locale = str
            let cnobj = JSON.parse(sessionStorage.getItem("cnobj"))
            let enobj = JSON.parse(sessionStorage.getItem("enobj"))
            this.cn_enobj = this.locale == 'zh' ?cnobj:enobj
        },
        goDetaile(i){
             this.$router.push({path:'/inCaseDetaile', query:{type:'information',id: i.id}})
        },
        // 获取被投企业
        getBusinessData(){
            this.$axios({
                method:'get',
                url:'/api/content/list/business?page_size=12&page='+this.page,
            }).then(res=>{
                if(res.data.data.data.length === 0 ){
                    this.$message({
                        message: '没有更多数据了！',
                        type: 'warning'
                    });
                    this.isDisable = true
                    return
                }
                this.isDisable = false
                this.item3Arr = this.item3Arr.concat(res.data.data.data)
            })  
        },
        // 获取投资资讯
        getInformationData(){
            this.$axios({
                method:'get',
                url:'/api/content/list/information?page_size=1',
            }).then(res=>{
                this.information = res.data.data.data[0]
            })  
        },
        // 加载更多
        loadMoreEV(){
            if(this.isDisable)return
            this.page = this.page + 1 
            this.getBusinessData()
        },
        gbbeitouEV(){
            this.showgbbeitou = false
        }
    }
}
</script>

<style lang="less" scoped>

@import "../../src/assets/css/pc/investmentCase_pc.less"; 

</style>